
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="allocation" class="col-12">
      <h5 class="mb-3">Allocation Details</h5>
      <div class="card overflow-hidden">
        <div class="card-body">
          <div class="row gx-2 gx-lg-4">
            <div class="col-10 col-sm-8">
              <div class="d-flex align-items-center justify-content-start mb-3">
                <div class="">
                  <div class="mb-2"><strong>Number:</strong> <span>{{allocation.friendly_id}}</span></div>
                </div>
              </div>
            </div>
            <div class="col-2 col-sm-4 text-end">
              <div class="dropdown dropstart no-arrow">
                <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li @click.prevent="deleteItem()">
                    <button class="dropdown-item" type="button">
                      Delete Allocation
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12">
              <div class="row g-3">
                <div class="col-12">
                  <div class=""><strong>Total Quantity:</strong> <span class="fw-bold">{{ allocation.quantity }}</span></div>
                  <div class="mt-2"><strong>Date created:</strong> <span>{{ $filters.date(allocation.created_at, 1) }}</span></div>
                  <label class="mt-2 form-label fw-bold">Description</label>
                  <div v-html="preText(allocation.description)"></div>
                </div>
                <div class="col-12">
                  <hr class="my-1">
                </div>
                <div class="col-12">             
                  <h6>Medicine</h6>
                  <p v-if="allocation.medicine" class="mb-0"> <router-link :to="`/medicines/${allocation.medicine.id}`"> <strong>{{allocation.medicine.name}}</strong> - {{allocation.medicine.description}}</router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-md-6">
          <statistic-apex-count
            title="ALLOCATIONS IN PROGRESS"
            description="Allocated quantities are not completely confirmed"
            :count-end="allocation.items_in_progress_count"
            :total="allocation.hospitals_count"
            :figure="allocation.items_in_progress_count"
            chart-color="#FFB02f"
          />
        </div>
        <div class="col-md-6">
          <statistic-apex-count
            title="DISPATCHED ALLOCATIONS"
            description="Allocated quantities are completely confirmed"
            :count-end="allocation.dispatched_items_count"
            :total="allocation.hospitals_count"
            :figure="allocation.dispatched_items_count"
          />
        </div>
      </div>
      <div class="pt-5">
        <h5>List of Hospitals</h5>
        <allocated-items  />
      </div>
      <div class="row pt-5 g-4">
        <div class="col-md-6">          
          <div v-if="allocation.allocator" class="card overflow-hidden">
            <div class="bg-primary py-4">
            </div>
            <div class="card-body text-center">
              <span class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
                <img :src="absoluteUrl(allocation.allocator.image)" class="avatar-img rounded-circle border border-4 border-card" alt="">
              </span>
              <div>
                <p class="small text-muted mb-1">
                  Allocated by
                </p>
                <h3 class="card-title mb-1">
                  <button @click.prevent="navigateTo(allocation.allocator.id)"
                    type="button" class="btn-link btn">
                    {{allocation.allocator.first_name}} {{allocation.allocator.last_name}}
                  </button>
                </h3>
                <p class="mb-0">{{allocation.allocator.mobile}}</p>
                <p class="mb-0">{{allocation.allocator.email}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6"> 
          <div v-if="allocation.dispatcher" class="card overflow-hidden">
            <div class="bg-warning py-4">
            </div>
            <div class="card-body text-center">
              <span class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
                <img :src="absoluteUrl(allocation.dispatcher.image)" class="avatar-img rounded-circle border border-4 border-card" alt="">
              </span>
              <div>
                <p class="small text-muted mb-1">
                  Dispatcher
                </p>
                <h3 class="card-title mb-1">
                  <button @click.prevent="navigateTo(allocation.dispatcher.id)"
                    type="button" class="btn-link btn">
                    {{allocation.dispatcher.first_name}} {{allocation.dispatcher.last_name}}
                  </button>
                </h3>
                <p class="mb-0">{{allocation.dispatcher.mobile}}</p>
                <p class="mb-0">{{allocation.dispatcher.email}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import AllocatedItems from './AllocatedItems.vue'
import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue'

export default {
  name: "allocation-show",
  components:{
    IsLoading,
    AllocatedItems,
    StatisticApexCount,
  },
  data() {
    return {
      isLoading: true,
      allocation: null
    }
  },
  watch: {
    '$route.params.allocationId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/account'});
      }else{
        this.$router.push({path:`/users/${id}`});
      }
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/allocations/${this.$route.params.allocationId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.allocation = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      this.confirmDelete({
        text: "You want to delete case permanently",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/allocations/${this.allocation.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/allocations'})
            }
          })
        }
      });
    },
    initializeAll(){
      
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

